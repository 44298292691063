body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Camera {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.Camera h1 {
  font-weight: 400;
  margin: 0;
  font-size: 30px;
}

.Camera h4 {
  font-weight: 300;
  margin: 0;
  font-size: 20px;
}

.Camera .camera-container {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
}

.watermark {
  position: fixed;
  bottom: -70px;
  right: -70px;
  opacity: 0.2;
  height: 250px;
  cursor: pointer;
}

.rotating {
  -webkit-animation: rotating 60s linear infinite;
  animation: rotating 60s linear infinite;
}

.love {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  font-size: 14px;
}

.love a {
  color: black;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

